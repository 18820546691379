import { useEffect, useState } from 'react'
import queryString from 'query-string'
import {
  setCurrentUser,
  selectCurrentUser,
  setHeaderAuth,
  getHeaderAuth,
} from './app/store/authSlice'
import {
  selectCurrentDocument,
  setCourseId,
  setCurrentDocument,
  setEnrollmentId,
  setEnrollmentGroupId,
  setViewOnly,
} from 'app/store/uiSlice'
import HeightProvider from './app/components/HeightProvider'
import LoaderContainer from './app/components/containers/LoaderContainer'
import { Loader } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { ErrorMessageWithReload } from 'app/components/ErrorMessageWithReload'
import { SocketProvider } from 'app/context/socket'
import { parseJwt } from 'lib'

const attendanceRouteRegex = /^\/attendance\/.+$/

function Layout({ children }) {
  const dispatch = useDispatch()
  const {
    token,
    documentId,
    enrollmentId,
    enrollmentGroupId,
    courseId,
    skipEnrollmentValidation,
  } = queryString.parse(window.location.search)
  const [authStatus, setAuthStatus] = useState('idle')
  const currentUser = useSelector(selectCurrentUser)
  const headerAuth = useSelector(getHeaderAuth)
  const currentDocument = useSelector(selectCurrentDocument)
  // const isAssignmentRoute = ['assignment', 'assigment'].some((route) =>
  //   window.location.pathname.includes(route)
  // )
  const isAttendanceRoute = attendanceRouteRegex.test(window.location.pathname)

  if (!window.location.pathname.includes('attendance')) {
    require('styles/App.css')
  }

  // Authorization
  useEffect(() => {
    if (token) {
      // Decodificar JWT
      setAuthStatus('loading')
      const userData = parseJwt(token)

      dispatch(setHeaderAuth(token))
      dispatch(
        setCurrentUser({
          ...userData,
          hideFeedbackDates:
            userData.id === process.env.REACT_APP_HIDE_FEEDBACK_DATES_USER_ID,
        })
      )
      setAuthStatus('success')
    }

    if (documentId && currentDocument === null) {
      dispatch(setCurrentDocument(documentId))
    }

    if (courseId) {
      dispatch(setCourseId(courseId))
    }

    if (enrollmentId) {
      dispatch(setEnrollmentId(enrollmentId))
      dispatch(setViewOnly(false))
    }

    if (enrollmentGroupId) {
      dispatch(setEnrollmentGroupId(enrollmentGroupId))
    }
  }, [token, dispatch])

  let content = children

  if (['idle', 'loading'].includes(authStatus) && !isAttendanceRoute) {
    return (
      <HeightProvider>
        <LoaderContainer>
          <Loader active />
        </LoaderContainer>
      </HeightProvider>
    )
  }

  if (!token && !isAttendanceRoute) {
    return (
      <HeightProvider>
        <span>401</span>
      </HeightProvider>
    )
  }

  // if (
  //   !isAttendanceRoute &&
  //   currentUser.isStudent &&
  //   isAssignmentRoute &&
  //   !skipEnrollmentValidation
  // ) {
  //   content = (
  //     <ErrorMessageWithReload
  //       title="No pudimos cargar esta actividad"
  //       description="¡Pero no te preocupes! Sólo vuelve a cargar la página"
  //       action="Recargar"
  //     />
  //   )
  // }

  return (
    <HeightProvider>
      <SocketProvider token={headerAuth}>{content}</SocketProvider>
    </HeightProvider>
  )
}

export default Layout
